import service from '@/utils/request'; // @Tags SysOperationRecord
// @Summary 分页获取SysOperationRecord列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取SysOperationRecord列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /sysOperationRecord/getSysOperationRecordList [get]

export var getSysOperationRecordList = function getSysOperationRecordList(params) {
  return service({
    url: '/sysOperationRecord/getSysOperationRecordList',
    method: 'get',
    params: params
  });
};