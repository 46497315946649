//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getSysOperationRecordList } from '@/api/sysOperationRecord'; // 此处请自行替换地址

import { formatTimeToStr } from '@/utils/date';
import infoList from '@/mixins/infoList';
export default {
  name: 'SysOperationRecord',
  filters: {
    formatDate: function formatDate(time) {
      if (time !== null && time !== '') {
        var date = new Date(time);
        return formatTimeToStr(date, 'yyyy-MM-dd hh:mm:ss');
      } else {
        return '';
      }
    },
    formatBoolean: function formatBoolean(bool) {
      if (bool !== null) {
        return bool ? '是' : '否';
      } else {
        return '';
      }
    }
  },
  mixins: [infoList],
  data: function data() {
    return {
      listApi: getSysOperationRecordList,
      dialogFormVisible: false,
      type: '',
      deleteVisible: false,
      multipleSelection: [],
      formData: {
        ip: null,
        method: null,
        path: null,
        status: null,
        latency: null,
        agent: null,
        error_message: null,
        user_id: null
      }
    };
  },
  created: function created() {
    this.getTableData();
  },
  methods: {
    // 条件搜索前端看此方法
    onSubmit: function onSubmit() {
      this.page = 1;
      this.pageSize = 10;
      this.getTableData();
    },
    fmtBody: function fmtBody(value) {
      try {
        return JSON.parse(value);
      } catch (err) {
        return value;
      }
    }
  }
};